<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="flex flex-col items-center justify-start w-full text-yellow-50"
      >
        <div class="py-1 max-w-5xl w-full flex-none">
          <div class="p-4">
            <div>
              <a
                v-on:click="$router.go(-1)"
                class="flex items-center space-x-2 text-center cursor-pointer"
                ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
              >
            </div>
          </div>
        </div>
      </div>

      <div
        class="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-md md:px-20 lg:px-8 lg:py-10"
      >
        <div class="grid">
          <div
            class="bg-gradient-to-r shadow rounded-lg ring ring-green-800 ring-offset-2"
            style="background-color: #272827"
          >
            <div class="m-4 text-white">
              <h3 class="text-sm font-bold">{{ dataModel.brand }}</h3>

              <h2
                class="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-green-800"
              >
                {{ dataModel.model }}
              </h2>

              <a
                class="text-3xl font-black text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-green-400"
              >
                30,293.-
              </a>
              <b class="text-sm">THB / Week</b>
            </div>

            <center>
              <img
                :src="require(`@/assets/img/car/${dataModel.img}`)"
                style="margin-left: -10px; width: 60%"
              />
            </center>

            <div class="text-gray-500" style="margin: 30px">
              <h5
                class="text-white text-md font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-green-800"
              >
                Overview
              </h5>
              <div class="grid grid-cols-2 gap-4 lg:grid-cols-2 mt-2">
                <div class="">
                  <div class="grid grid-cols-3 gap-1 lg:grid-cols-3">
                    <div class="flex flex-col items-center justify-center">
                      <i class="fas fa-car" style="font-size: 28px"></i>
                    </div>
                    <div class="col-span-2 flex flex-col justify-center">
                      <p style="font-weight: 600; font-size: 12px">Type</p>
                      <a
                        class="text-sm l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                      >
                        {{ dataModel.typeCar }}
                      </a>
                    </div>
                  </div>
                </div>

                <div class="">
                  <div class="grid grid-cols-3 gap-1 lg:grid-cols-3">
                    <div class="flex flex-col items-center justify-center">
                      <i class="fas fa-heart" style="font-size: 28px"></i>
                    </div>
                    <div class="col-span-2 flex flex-col justify-center">
                      <p style="font-weight: 600; font-size: 12px">
                        No. of seats
                      </p>
                      <a
                        class="text-sm l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                      >
                        {{ dataModel.amount }}
                      </a>
                    </div>
                  </div>
                </div>

                <div class="">
                  <div class="grid grid-cols-3 gap-1 lg:grid-cols-3">
                    <div class="flex flex-col items-center justify-center">
                      <i class="fas fa-layer-group" style="font-size: 26px"></i>
                    </div>
                    <div class="col-span-2 flex flex-col justify-center">
                      <p style="font-weight: 600; font-size: 12px">
                        Model Year
                      </p>
                      <a
                        class="text-sm l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                      >
                        {{ dataModel.year }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <h5
                class="mt-3 text-white text-md font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-green-800"
              >
                Performance
              </h5>

              <div class="grid grid-cols-2 gap-4 lg:grid-cols-2 mt-2">
                <div class="">
                  <div class="grid grid-cols-3 gap-1 lg:grid-cols-3">
                    <div class="flex flex-col items-center justify-center">
                      <i
                        class="fas fa-tachometer-alt"
                        style="font-size: 28px"
                      ></i>
                    </div>
                    <div class="col-span-2 flex flex-col justify-center">
                      <p style="font-weight: 600; font-size: 12px">0 - 100</p>
                      <a
                        class="text-sm l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                      >
                        {{ dataModel.acceleration }}
                      </a>
                    </div>
                  </div>
                </div>

                <div class="">
                  <div class="grid grid-cols-3 gap-1 lg:grid-cols-3">
                    <div class="flex flex-col items-center justify-center">
                      <i class="far fa-compass" style="font-size: 28px"></i>
                    </div>
                    <div class="col-span-2 flex flex-col justify-center">
                      <p style="font-weight: 600; font-size: 12px">Top speed</p>
                      <a
                        class="text-sm l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                      >
                        {{ dataModel.max_speed }} km / h
                      </a>
                    </div>
                  </div>
                </div>

                <div class="">
                  <div class="grid grid-cols-3 gap-1 lg:grid-cols-3">
                    <div class="flex flex-col items-center justify-center">
                      <i class="fas fa-route" style="font-size: 26px"></i>
                    </div>
                    <div class="col-span-2 flex flex-col justify-center">
                      <p style="font-weight: 600; font-size: 12px">
                        Range (advertised)
                      </p>
                      <a
                        class="text-sm l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                      >
                        {{ dataModel.distance }}
                      </a>
                    </div>
                  </div>
                </div>

                <div class="">
                  <div class="grid grid-cols-3 gap-1 lg:grid-cols-3">
                    <div class="flex flex-col items-center justify-center">
                      <i
                        class="fas fa-battery-three-quarters"
                        style="font-size: 26px"
                      ></i>
                    </div>
                    <div class="col-span-2 flex flex-col justify-center">
                      <p style="font-weight: 600; font-size: 12px">
                        Battery Capacity
                      </p>
                      <a
                        class="text-sm l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                      >
                        {{ dataModel.battery_capacity }} kWh
                      </a>
                    </div>
                  </div>
                </div>

                <div class="">
                  <div class="grid grid-cols-3 gap-1 lg:grid-cols-3">
                    <div class="flex flex-col items-center justify-center">
                      <i class="fas fa-horse-head" style="font-size: 26px"></i>
                    </div>
                    <div class="col-span-2 flex flex-col justify-center">
                      <p style="font-weight: 600; font-size: 12px">
                        Horsepower
                      </p>
                      <a
                        class="text-sm l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                      >
                        {{ dataModel.horsepower }} kWh
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <h5
                class="mt-3 text-white text-md font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-green-800"
              >
                Charging
              </h5>

              <div class="grid grid-cols-2 gap-4 lg:grid-cols-2 mt-2">
                <div class="">
                  <div class="grid grid-cols-3 gap-1 lg:grid-cols-3">
                    <div class="flex flex-col items-center justify-center">
                      <i class="fas fa-gas-pump" style="font-size: 26px"></i>
                    </div>
                    <div class="col-span-2 flex flex-col justify-center">
                      <p style="font-weight: 600; font-size: 12px">
                        Slow Charge Time
                      </p>
                      <a
                        class="text-sm l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                      >
                        {{ dataModel.low_charge }} / h
                      </a>
                    </div>
                  </div>
                </div>

                <div class="">
                  <div class="grid grid-cols-3 gap-1 lg:grid-cols-3">
                    <div class="flex flex-col items-center justify-center">
                      <i
                        class="fas fa-charging-station"
                        style="font-size: 28px"
                      ></i>
                    </div>
                    <div class="col-span-2 flex flex-col justify-center">
                      <p style="font-weight: 600; font-size: 12px">
                        Slow Charge Time
                      </p>
                      <a
                        class="text-sm l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                      >
                        {{ dataModel.speed_charge }} / minute
                      </a>
                    </div>
                  </div>
                </div>

                <div class="">
                  <div class="grid grid-cols-3 gap-1 lg:grid-cols-3">
                    <div class="flex flex-col items-center justify-center">
                      <div v-for="p in dataModel.power_charge" :key="p">
                        <img
                          style="
                            border-radius: 50%;
                            padding: 2px;
                            margin-left: 2px;
                          "
                          v-if="p == 'AC Type 1'"
                          src="@/assets/img/connectors/1.svg"
                        />

                        <img
                          style="
                            border-radius: 50%;
                            padding: 2px;
                            margin-left: 2px;
                          "
                          v-if="p == 'AC Type 2'"
                          src="@/assets/img/connectors/2.svg"
                        />

                        <img
                          style="
                            border-radius: 50%;
                            padding: 2px;
                            margin-left: 2px;
                          "
                          v-if="p == 'DC CSS2'"
                          src="@/assets/img/connectors/CCS2.svg"
                        />

                        <img
                          style="
                            border-radius: 50%;
                            padding: 2px;
                            margin-left: 2px;
                          "
                          v-if="p == 'DC CHAdeMO'"
                          src="@/assets/img/connectors/Chademo.svg"
                        />
                      </div>
                    </div>
                    <div class="col-span-2 flex flex-col justify-center">
                      <p style="font-weight: 600; font-size: 12px">
                        Charge Type
                      </p>

                      <div
                        class="text-sm l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                        v-for="p in dataModel.power_charge"
                        :key="p"
                      >
                        {{ p }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex; justify-content: flex-end">
              <div style="margin-right: 1%">
                <div style="display: flex">
                  <a v-bind:href="'/booking/' + dataModel._id">
                    <button
                      class="w-42 flex-none text-sm px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-blue-600 to-green-600"
                    >
                      จองรถ
                    </button>
                  </a>
                  <router-link v-bind:to="'/booking/' + dataModel._id">
                  </router-link>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>

      <div
        class="flex flex-col items-center justify-start flex-grow w-full text-yellow-50 p-3 pb-28"
      >
        <footer>
          <StickFooter />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import FundSummary from "@/components/FundSummary.vue";
import Social from "@/components/Social.vue";
import LiveTx from "@/components/LiveTx.vue";
import Stats from "@/components/Stats.vue";
import OurTeam from "@/components/OurTeam.vue";
import Roadmap from "@/components/Roadmap.vue";
import { mapGetters, mapActions } from "vuex";

import transactions from "./transactions_bet.vue";

import car from "@/api/car";

import { API_URL } from "../../config";

import axios from "axios";

import { POOL_ADDRESS, VERSION } from "../../config";

export default {
  data() {
    return {
      version: VERSION,
      cars: [],
      model: this.$route.params.model,
      dataModel: {},
    };
  },
  components: {
    Social,
    LiveTx,
    Stats,
    OurTeam,
    Roadmap,
    ConnectWallet,
    StickFooter,
    FundSummary,
    transactions,
  },
  methods: {
    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },
  },
  created() {
    if (this.$route.params.ref) {
      localStorage.setItem("Ref", JSON.stringify(this.$route.params.ref));
    } else {
      localStorage.setItem("Ref", JSON.stringify(POOL_ADDRESS));
    }

    axios.get(API_URL + "/carDetail/" + this.model).then((response) => {
      this.dataModel = response.data.car;
    });
  },
};
</script>
